.sprite-svg-area{
	height: 0;
	overflow: hidden;
}

#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.wrapper{
		flex-grow: 1;
	}
}


.owl-carousel:not(.owl-loaded) {
	overflow: hidden;
	white-space: nowrap;

	& > *{
		display: inline-block;
		white-space: normal;
	}

	@include media-breakpoint-up(xl) {
		--item-width: calc(100% / attr(data-xl));

		& > * {
			min-width: var(--item-width);
		}
	}
}
html{
	scroll-behavior: smooth;
}
body{
	font-family: 'montserratmedium';
	font-size: 14px;
	color: #633621;
}
a{
	color: inherit;

	&:hover{
		color: inherit;
	}
}
h1, h2, h3, h4, h5, h6, figure{
	margin-bottom: 0px;
}
.line__clamp {
	max-height: calc(var(--linhas, 2) * 1.5em);
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--linhas, 2);
}
[data-animate] {
	opacity: 0;
}
.link__grande{
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
}
.barrinha{
	margin-right: 5px;
	margin-left: 5px;
}
.conteudo__gerenciavel{
	text-align: justify;

	img{
		max-width: 100%;
		height: auto;
	}
}
.loader{
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&:after{
		content: '';
		display: inline-block;
		width: 50px;
		height: 50px;
		animation: loader 2s linear infinite;
		border: 3px solid #DDDDDD;
		border-radius: 50%;
		border-left: 3px solid transparent;
	}
}
@keyframes loader{
	0%{
		transform: rotate(0deg);
	}
	50%{
		transform: rotate(180deg);
	}
	100%{
		transform: rotate(359deg);
	}
}
