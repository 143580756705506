.titulo__viajes{
	font-family: 'hobostd';
	font-size: 30px;
	margin-bottom: 50px;
}
.viajes__titulo{
	font-family: 'hobostd';
	font-size: 30px;
	color: #edc40b;
	background-color: #633621;
	text-align: center;
	padding: 5px 0px;
}
.viajes__header{
	font-family: 'montserratmedium';
	color: #633621;
	font-size: 13px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	border-bottom: 1px solid #633621;
	width: 100%;
	background-color: #f7f5f4;
	padding: 7px 12px;

	&[aria-expanded="true"]{
		font-family: 'montserratblack';

		.botao__viajes{
			&:after{
				content: '\f068';
			}
		}
	}

	&:focus{
		outline: none;
	}
}
.botao__viajes{
	width: 20px;
	min-width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #633621;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	&:after{
		content: '\f067';
		display: inline-block;
		font-family: 'Font Awesome 5 Free';
		font-size: 13px;
		line-height: 13px;
		font-weight: 900;
		color: #fff;
		position: relative;
		padding-left: 1px;
	}
}
.viajes__item{
	&:last-child{
		.viajes__header{
			border-bottom: none;
		}
	}
}
.viajes__info{
	font-family: 'montserratregular';
	font-size: 13px;
	line-height: 17px;
	padding: 10px 30px 20px 15px;
	background-color: #e1d9d5;

	a{
		display: block;
		margin-bottom: 20px;
		text-align: justify;

		&:last-child{
			margin-bottom: 0px;
		}

		&:hover{
			font-family: 'montserratbold';
			text-decoration: none;
		}
	}
}
.viajes__card{
	display: block;
	text-align: center;
	margin-bottom: 38px;

	&:hover{
		text-decoration: none;

		.viajes__img{
			box-shadow: 0px 0px 20px 0px rgba(#000, 0.8);
		}
	}
}
.viajes__img{
	margin-bottom: 10px;
	border-radius: 30px;
	overflow: hidden;
	box-shadow: 0px 0px 20px 0px rgba(#000, 0);
	transition: all 0.3s linear;
}
.nome__viajes{
	font-family: 'montserratblack';
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
}
.desc__viajes{
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 1.2em;
}
.paginacao__custom{
	display: flex;
	justify-content: flex-end;
}
.paginacao__custom__link{
	width: 38px;
	height: 38px;
	background-color: #633621;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	line-height: 24px;
	border-radius: 50%;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: #fff;
		background-color: #edc40b;
	}

	&:first-child{
		margin-right: 20px;

		span{
			margin-right: 4px;
		}
	}
	&:last-child{
		span{
			margin-left: 4px;
		}
	}
}
.viajes__detalhes__titulo{
	font-family: 'montserratblack';
	font-size: 16px;
	margin-bottom: 20px;
}
.viajes__detalhes__desc{
	font-family: 'montserratmedium';
	font-size: 13px;
	margin-bottom: 35px;
}
@include media-breakpoint-down (md) {
	.viajes__lista{
		margin-bottom: 30px;
	}
}
