.topo__faixa{
	background-color: #633621;
	color: #fff;
	padding: 3px 0px;
	font-size: 13px;
}
.faixa__segura{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.faixa__sociais{
	display: flex;
	margin-right: 40px;
	align-items: center;
}
.faixa__email,
.faixa__tel{
	display: flex;
	align-items: center;
}
.mail__icone,
.tel__icone,
.sociais__link{
	display: flex;
	width: 33px;
	height: 33px;
	background-color: #fff;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	color: #633621;
	border: 2px solid #633621;
	font-size: 16px;
	line-height: 16px;
	transition: all 0.3s linear;

	&:hover{
		color: #a87d16;
		text-decoration: none;
		border: 2px solid #a87d16;
	}
}
.faixa__email,
.faixa__tel{
	&:hover{
		text-decoration: none;

		.mail__icone,
		.tel__icone{
			color: #a87d16;
			border: 2px solid #a87d16;
		}
	}
}
.sociais__link{
	margin-right: 5px;
}
.mail__icone,
.tel__icone{
	margin-right: 15px;
}
.faixa__email{
	margin-right: 40px;
}
.bg__menu{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.7);
	position: fixed;
	z-index: 999;
	display: none;
}
.main__menu{
	font-size: 13px;
	color: #633621;
}
.menu__link{
	position: relative;
	&:hover{
		text-decoration: none;
		&:after{
			opacity: 1;
		}
	}

	&:after{
		width: 100%;
		height: 14px;
		content: '';
		background-color: #edc40b;
		position: absolute;
		z-index: -1;
		left: 0;
		bottom: 13px;
		border-radius: 15px;
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.menu__item--active{
	.menu__link{
		&:after{
			opacity: 1;
		}
	}
}
@include media-breakpoint-only (xl) {
	.topo__main{
		padding: 30px 0px 10px 0px;
		border-bottom: 10px solid #633621;
	}
	.main__segura{
		display: flex;
		justify-content: space-between;
	}
	.topo__mbl{
		display: none;
	}
	.main__menu{
		display: flex;
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		flex-grow: 1;
		justify-content: space-between;
	}
	.menu__link{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		padding: 15px 5px;
		text-align: center;
	}
	.main__logo{
		margin-right: 15px;
	}
}

@include media-breakpoint-down (lg) {
	.mbl__segura{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mbl__botao{
		background-color: transparent;
		color: #633621;
		border: none;
		font-size: 20px;
		line-height: 20px;

		&:focus{
			outline: none;
		}
	}
	.topo__mbl{
		border-bottom: 10px solid #633621;
		padding: 10px 0px;
	}
	.topo__main{
		position: fixed;
		top: 0;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		z-index: 1000;
		background-color: #fff;
		transition: all 0.3s linear;
		border-right: 5px solid #633621;
	}
	.topo__main--shown{
		left: 0;
	}
	.main__segura{
		padding: 0;
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 20px;
	}
	.main__menu{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}
	.menu__link{
		display: block;
		padding: 10px;
		margin: 0px 10px;

		&:after{
			bottom: 8px;
		}
	}
}
@include media-breakpoint-down (sm) {
	.topo__faixa{
		display: none;
	}
}
