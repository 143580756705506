.links-uteis#modelo-3{

	.links-uteis{
		overflow: hidden;
		width: 100%;

		.content{
			padding-left:0;
			margin-bottom:0;
			list-style:none;
			display: flex;
			flex-wrap:wrap;
			border:1px solid #CCC;

			li{
				border:1px solid #CCC;
				width: 25%;

				&,
				a{
					display: flex;
				}

				a{
					width: 100%;
					align-items: center;
					justify-content:center;
					padding: 10px;
					transition:all 0.3s linear;
					overflow: hidden;
					position: relative;

					img{
						filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
					    filter: gray; /* IE6-9 */
					    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

					    transition: all 0.3s linear;
				    }

				    &:hover{
				    	img{
					    	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
							-webkit-filter: grayscale(0%);
						}
				    }
				}
			}
		}
	}
}