.btn__voltar{
	width: 35px;
	height: 35px;
	border-radius: 50%;
	position: fixed;
	bottom: -60px;
	left: 50%;
	transform: translateX(-50%);
	border: none;
	background-color: #633621;
	z-index: 998;
	transition: all 0.5s linear;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 3px 2px rgba(#faf0c2, 0.2);

	span{
		padding-bottom: 1px;
	}

	&:hover{
		background-color: lighten(#633621, 10%);
	}

	&:focus{
		outline: none;
	}

	&.active{
		bottom: 20px;
	}

}
.rodape__conteudo{
	position: relative;
	padding-bottom: 130px;
	padding-top: 40px;

	&:before{
		width: 100%;
		content: '';
		height: 100%;
		top: 0;
		left: 0;
		background-color: #faf0c2;
		z-index: -2;
		position: absolute;
	}
	&:after{
		width: 100%;
		content: '';
		height: 100%;
		top: -40px;
		left: 0;
		z-index: -1;
		position: absolute;
		background-image: url('../images/bg__rodape.png');
		background-size: 1920px 214px;
		background-repeat: no-repeat;
		background-position: center bottom;
	}
}
.rdp__titulo{
	font-family: 'montserratblack';
	font-size: 20px;
	color: #633621;
	margin-bottom: 22px;
}
.empresa__rdp__desc{
	max-width: 260px;
	margin-left: 0;
	margin-right: auto;
	text-align: justify;
	color: #633621;
}
.servicos__rdp__desc{
	color: #633621;
}
.rodape__icone{
	min-width: 35px;
	width: 35px;
	height: 35px;
	color: #faf0c2;
	background-color: #633621;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	border-radius: 50%;
	margin-right: 10px;
}
.rodape__local,
.rodape__telefone,
.rodape__email,
.rodape__horario,
.rodape__sociais{
	display: flex;
	align-items: center;
}
.rodape__local,
.rodape__telefone,
.rodape__email{
	margin-bottom: 5px;
}
.rodape__horario{
	margin-bottom: 22px;
}
.rodape__sociais{
	justify-content: flex-end;

	.rodape__icone{
		&:hover{
			text-decoration: none;
			color: darken(#faf0c2, 10%);
		}
	}
}
.rodape__creditos{
	padding: 10px 0px;
	background-color: #f5df79;
	font-size: 10px;
	text-align: center;
	font-family: 'montserratregular';

	.container{
		display: flex;
		flex-direction: column;
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover{
		text-decoration: none;
	}
}
.gv8__logo{
	margin-left: 10px;
}
@include media-breakpoint-down (md) {
	.rodape__empresa{
		text-align: center;
		max-width: 100%;
	}
	.empresa__rdp__desc{
		max-width: 100%;
		margin-bottom: 30px;
	}
	.rodape__servicos{
		text-align: center;
		margin-bottom: 30px;
	}
	.rodape__local,
	.rodape__telefone,
	.rodape__email,
	.rodape__horario{
		flex-direction: column;
		text-align: center;
		margin-bottom: 15px;
	}
	.rodape__icone{
		margin-right: 0px;
	}
	.rodape__sociais{
		justify-content: center;

		.rodape__icone{
			&:first-child{
				margin-right: 20px;
			}
		}
	}
}
