#orcamento-mod-2{

	h2 {
		font-size: 26px;
	}

	.h3{
		font-size: 16px;
		color: #a9a9a9;
	}

	.hr{
		margin-bottom: 40px;
		border-top: 2px dashed rgba(#000,0.1);
	}

	.form-group{
		position: relative;

		.form-control{
			border-radius: 0;
			@extend .form-control-lg;
			border-right-width: 5px;

			&:focus ~ .icon-float{
				color: $blue;
			}
		}

		.icon-float{
			position: absolute;
			top: 50%;
			right:5px;
			transform: translateY(-50%);
			padding: 0.5rem 1rem;
			display: inline-block;
			margin-bottom:0;

			background: #FFF;

			&.fa-flip-horizontal{
				transform: translateY(-50%) scale(-1,1);
			}
		}
	}

	textarea.form-control{
		height: auto !important;
	}

	.btn-enviar{
		@include button-variant(#D02C35, #D02C35);
		color: #FFF;
		border-radius: 0;
		@extend .btn-lg;

		&:hover{
			color: #FFF;
		}
	}
}