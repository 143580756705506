.page-header{
	font-family: 'Roboto', sans-serif;
	background: #f0f0f0;
	padding-top:40px;
	padding-bottom:40px;
	text-align: center;
	margin-bottom: 60px;

	.breadcrumb{
		padding:0 30px 15px 30px;
		min-width: 250px;
		max-width: 100%;
		background: none;
		display: inline-flex;
		justify-content:center;
		margin-bottom: 0;
		border-radius:0;
		border-bottom: 2px solid rgba(#000,0.2);
		position: relative;

		span{
			color: #888;
			cursor: default;
		}

		&:before{
			content:'';
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translateX(-50%);
			height: 5px;
			width: 60px;
			background: #EC7D00;
		}

		li + li:before{
			content:'/';
			display: inline-block;
			margin:0 10px;
		}
	}

	h1{
		font-family: 'Roboto', sans-serif;
		font-weight: 900
	}

	.detail{
		border: 1px solid currentcolor;
		border-top: none !important;
	}

	.subtitle{
		font-size: 20px;
		// margin-bottom:5px;
	}

	.detail-title{
		width: 40px;
		height:3px;
		position: relative;
		font-size: 20px;


		&:before,
		&:after{
			content:'';
			display:block;
			position:absolute;
			width: calc(50% - 2px);
			height:100%;
		}

		&:before{
			left:0;
			background: #FFF;
		}

		&:after{
			right:0;
			background: #FF002E;
		}
	}

}

svg.detail-titles{
	display:inline-block;
	vertical-align: middle;
	border:none;

	use{
		fill: currentcolor;
	}
}

.paginacao{
	margin-bottom: 60px;
	text-align:center;
	color:#333;

	& > *{
		display: inline-block;
		padding:0.3em 0.6em;
		border:2px solid #333;


		& + *{
			margin-left:0.3em;
		}
	}

	.active{
		background: #333;
		color: #FFF;
	}

	a:hover{
		background: rgba(#000,0.1);
		text-decoration: none;
	}
}

.md-form{
	position:relative;
	padding-top:1.5rem;
	padding-bottom:1rem;

	& > label{
		margin:0;
		padding:0.375rem 0.75rem;
		position:absolute;
		
		@include media-breakpoint-down(sm){
			top:0;
			padding:0;
		}

		@include media-breakpoint-up(md){
			top:1.5rem;
			transition:all 0.3s linear;
		}
	}

	.form-control{
		border-radius:0;

		&.form-control-lg ~ label{
			padding: 0.5rem 1rem;
			font-size:1.25rem;
		}

		&.form-control-sm ~ label{
			padding: 0.25rem 0.5rem;
			font-size:0.875rem;
		}
	}

	.hover-eff{
		width:0;
		height:2px;
		background:$green;
		transition:width 0.3s linear;
	}

	.form-control:focus ~ .hover-eff,
	&.active .hover-eff{
		width: 100%;
	}

	&.has-success{
		.hover-eff{
			background: materialColor('green','green');
		}

		label{
			color: materialColor('green','darken-2') !important;
		}
	}

	&.has-error{
		.hover-eff{
			background: materialColor('red','red');
		}

		label{
			color: materialColor('red','red') !important;
		}
	}

	&.has-warning{
		.hover-eff{
			background: materialColor('orange','darken-2');
		}

		label{
			color: materialColor('orange','darken-2') !important;
		}
	}

	@include media-breakpoint-up(md) {
		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}
	}
}

textarea.form-control{
	height:auto !important;
}

.select-custom{
	position:relative;

	output{
		display: block;
	}

	.drop-options{
		background: #FFF;
		padding: 0.75rem;
		border:1px solid rgba(#000,0.2);
		position:absolute;
		left:0;
		top:100%;
		width:100%;
		display: none;
		z-index:5;
	}

	input[disabled]{
		background: #FFF;
	}

	&.open{
		.drop-options{
			display: block;
		}
	}

	input[name="filtro"]{
		@extend .form-control;
		margin-bottom:10px;
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-left:-0.75rem;
		margin-right:-0.75rem;
		margin-bottom:0;
		max-height: 120px;
		overflow: auto;

		label{
			padding:0.5rem 0.75rem;
			display: block;
			margin-bottom:0;

			&:hover{
				background: rgba(#000,0.1);
			}
		}
		
		input{
			position:absolute;
			display: none;

			&:checked ~ label{
				background: $blue;
				color: #fff;
			}
		}
	}
}

#alertas{
	position: fixed;
	right:15px;
	top:90px;
	width: 350px;
	max-width: calc(100% - 30px);
	z-index: 999;

	.alert{
		border-radius: 0;
		border-color: rgba(#000,0.2);
		@extend .alert-dismissible;
		box-shadow:0 0 8px rgba(#000,.4);
		animation: rubberBand 0.6s linear;

		&.alert-success{
			background: materialColor('green', 'darken-2');
			color: #FFF;
		}

		&.alert-danger{
			background: materialColor('red', 'darken-2');
			color: #FFF;
		}

		&.alert-warning{
			background: materialColor('orange', 'darken-2');
			color: #FFF;
		}

		&.alert-info{
			background: materialColor('blue', 'darken-2');
			color: #FFF;
		}
	}

	.media-body{
		align-self: center;
	}

	.icon-alerta{
		font-size: 2em;
		margin-right: 10px;
	}

	.alert-success{}
}