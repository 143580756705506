.banner{
	width: 100%;
	max-width: 1920px;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	box-shadow: 0px 5px 20px rgba(#000, 0.4);
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__indicators{
	li{
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: #fff;
		position: relative;
		border: none;
		opacity: 1;
		transition: all 0.3s linear;

		&:after{
			content: '';
			width: 20px;
			height: 20px;
			border: 1px solid #fff;
			position: absolute;
			top: -3px;
			left: -3px;
			border-radius: 50%;
			opacity: 0;
			transition: all 0.3s linear;
		}

		&.active{
			background-color: #edc40b;

			&:after{
				opacity: 1;
			}
		}
	}
}
.btn__conteudo{
	color: #633621;
	background-color: #edc40b;
	font-family: 'montserratmedium';
	font-size: 14px;
	display: inline-block;
	border-radius: 30px;
	padding: 7px 20px 5px 20px;
	line-height: 14px;
	transition: all 0.3s linear;
	border: none;

	&:hover{
		color: #edc40b;
		background-color: #633621;
		text-decoration: none;
	}
	&:focus{
		outline: none;
	}
}
.chamada{
	background-color: #633621;
	color: #fff;
	padding: 40px 0px 0px 0px;
}
.chamada__titulo{
	display: flex;
	align-items: flex-end;
	font-family: 'montserratblack';
	font-size: 19px;
	line-height: 19px;
	margin-bottom: 10px;
}
.chamada__icone{
	margin-right: 10px;
	width: 42px;
}
.chamada__desc{
	padding-left: 52px;
	font-family: 'montserratregular';
	font-size: 14px;
	margin-bottom: 40px;
}
.roteiros{
	background-image: url('../images/bg__destinos.jpg');
	background-size: 1920px 420px;
	background-position: center top;
	background-repeat: no-repeat;
	padding: 50px 0px;
}
.roteiros__titulo__secao{
	font-family: 'hobostd';
	font-size: 30px;
	line-height: 34px;
	color: #ffffff;
	max-width: 460px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 50px;
	box-shadow: 0px 0px 10px rgba(#000, 0);
	transition: all 0.3s linear;
}
.roteiros__owl{
	.owl-item{
		padding: 10px;
	}
}
.roteiros__card{
	position: relative;
	padding-bottom: 80px;

	&:hover{
		.roteiros__img{
			box-shadow: 0px 0px 10px rgba(#000, 0.6);
		}
	}
}
.roteiros__img{
	width: 100%;
	max-width: 350px;
	border-radius: 40px;
	overflow: hidden;
}
.roteiros__txt{
	max-width: 240px;
	width: 100%;
	background-color: #633621;
	border-radius: 30px;
	text-align: center;
	padding: 35px 0px 30px 0px;
	position: absolute;
	right: 0;
	bottom: 0px;
}
.roteiros__titulo{
	font-family: 'montserratblack';
	font-size: 25px;
	color: #fff;
	--linhas: 1;
}
.roteiros__destino{
	display: block;
	margin-bottom: 30px;
	font-family: 'montserratsemibold';
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	--linhas: 1;
}
.roteiros__link{
	color: #edc40b;
	font-size: 14px;
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: lighten(#edc40b, 15%);
	}
}
.roteiros__btn{
	text-align: center;
}
.segura__secoes{
	position: relative;

	&:after{
		background-image: url('../images/bg__gerak.png');
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: 1920px 2564px;
		content: '';
		z-index: -1;
		top: 0;
		left: 0;
		height: calc(100% + 20px);
		width: 100%;
		position: absolute;
	}
}
.servicos__titulo__secao{
	font-family: 'hobostd';
	font-size: 30px;
	line-height: 34px;
	color: #633621;
	text-align: center;
}
.servico__sub{
	font-family: 'montserratsemibold';
	font-size: 19px;
	color: #633621;
	text-align: center;
	margin-bottom: 50px;
}
.servicos__box{
	display: flex;
	justify-content: center;
}
.servicos__card{
	position: relative;
	max-width: 270px;
	margin-right: 15px;
	margin-left: 15px;
	border-radius: 30px;
	overflow: hidden;
	padding: 200px 22px 40px 22px;
	display: block;
	transition: all 0.4s linear;
	color: #edc40b;

	&:hover{
		max-width: 550px;
		color: #633621;
		text-decoration: none;

		&:after{
			background-color: rgba(#edc40b, 0.75);
		}

		.btn__conteudo{
			color: #edc40b;
			background-color: #633621;
		}
	}


	&:before{
		background-position: center;
		background-repeat: no-repeat;
		background-image: var(--imagem);
		z-index: -2;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
	}
	&:after{
		background-color: rgba(#633621, 0.75);
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		position: absolute;
		transition: all 0.3s linear;
	}
}
.servicos__titulo{
	font-family: 'montserratblack';
	font-size: 25px;
	line-height: 32px;
	margin-bottom: 20px;
	height: 64px;
}
.servicos__desc{
	--linhas: 4;
	margin-bottom: 100px;
	height: 84px;
}
.servicos__link{
	text-align: center;
}
.newsletter{
	padding: 70px 0px;
}
.news__titulo{
	font-family: 'hobostd';
	font-size: 25px;
	color: #683a23;
	text-align: center;
	margin-bottom: 35px;
}
.news__form{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
}
.form__control{
	flex-grow: 1;
	max-width: 500px;
	margin-left: 40px;
	margin-right: 70px;
}
.news__input{
	border-radius: 12px;
	border: 3px solid #edc40b;
}
.destinos__titulo{
	font-family: 'hobostd';
	font-size: 25px;
	color: #683a23;
}
.destinos{
	position: relative;

	&:after{
		width: 100%;
		height: 20px;
		bottom: 0;
		left: 0;
		background-color: #faf0c2;
		content: '';
		position: absolute;
	}
}
.owl-carousel.roteiros__owl{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			width: 28px;
			height: 28px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #683a23;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:hover{
				background-color: lighten(#683a23, 15%);
			}
			&:focus{
				outline: none;
			}
		}
		.owl-next{
			right: -20px;
		}
		.owl-prev{
			left: -20px;
		}
	}
}
.owl-carousel.destinos__carousel{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			width: 28px;
			height: 28px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #683a23;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:focus{
				outline: none;
			}
			&:hover{
				background-color: lighten(#683a23, 15%);
			}
		}
	}
	.owl-prev{
		left: 10%;
	}
	.owl-next{
		right: 10%;
	}
	.owl-item{
		padding: 20px 0px;
		border-radius: 0px;
		transition: all 0.3s linear;

		&:hover{
			transform: scale(1.1);
			z-index: 10;

			.destinos__card{
				overflow: hidden;
				border-radius: 20px;

				&:after{
					opacity: 1;
				}
				.destinos__txt{
					opacity: 1;
				}
			}
		}
	}
	.owl-dots{
		display: none;
	}
}
.destinos__card{
	display: block;
	position: relative;
	max-width: 321px;
	width: 100%;
	transition: all 0.3s linear;
	border-radius: 0px;

	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.4);
		z-index: 1;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.destinos__txt{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0px 0px 30px 0px;
	z-index: 2;
	opacity: 0;
}
.nome__destino{
	font-family: 'montserratextrabold';
	font-size: 25px;
	--linhas: 1;
	text-align: center;
	color: #ffffff;
}
.destinos__link{
	text-align: center;
	color: #edc40b;
	font-family:'montserratmedium';
	font-size: 14px;
}
@include media-breakpoint-down (md) {
	.servicos__card{
		max-width: 550px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
	}
	.servicos__box{
		flex-direction: column;
	}
	.news__form{
		flex-direction: column;
	}
	.news__img{
		margin-bottom: 15px;
	}
	.form__control{
		margin-bottom: 15px;
		width: 95%;
		max-width: 600px;
		margin-right: auto;
		margin-left: auto;
	}
	.destinos__txt{
		opacity: 1;
	}
	.owl-carousel.roteiros__owl{
		.owl-nav{
			.owl-next{
				right: 0px;
			}
			.owl-prev{
				left: 00px;
			}
		}
	}
}

