.empresa#modelo-2{
	
	.img-girl{
		.lazyimage{
			margin-left: auto;
		}
	}

	.nossa-equipe{
		background: #61A93E;
		padding: 40px 0;
		color:#FFF;
		position: relative;

		h2{
			font-weight: 400;
			margin-bottom: 60px;

			&:after{
				content:'';
				display: block;
				width: 80px;
				height: 3px;
				margin-top: 10px;
				background: currentcolor;
			}

			span{
				font-weight: 900;
			}
		}

		ul,
		ol{
			li{
				margin-bottom: 10px;
			}
		}

		&:after{
			content:'';
			display: block;
			border:15px solid transparent;
			border-bottom-color: #FFF;
			position: absolute;
			bottom: 0;
			left: calc(50% - 15px);
		}
	}
}