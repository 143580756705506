#agenda-1{
	padding-bottom: 80px;

	.filtro-agenda{
		font-size: 20px;
		margin-bottom: 60px;

		p{
			&:after{
				content:'';
				display:block;
				width: 4em;
				height:5px;
				background: #1CC196;
				border-radius:5px;
				margin-top: 5px;
			}
		}
	}

	.filter-box{
		width: 600px;
		max-width: 100%;
		background: #E8E8E8;
		border-radius:5px;
		border-bottom: 3px solid rgba(#000,0.1);
		display: flex;
		flex-wrap:wrap;

		.filter-box-group{
			padding: 15px;

			@include media-breakpoint-down(sm){
				width: 100%;
			}

			@include media-breakpoint-up(md){
				width: 50%;
			}
		}

		.btn{
			width: 100%;
			border:none;
			border-bottom: 3px solid rgba(#000,0.1);
			background: #55C194;
			color: #FFF;
			text-shadow: -1px 1px rgba(#000,0.2);
			overflow: hidden;
			position:relative;

			&:before{
				content:'';
				display:block;
				width: 50%;
				height: 200%;
				position:absolute;
				top: 50%;
				left: -100%;
				transform: translateY(-50%) rotate(15deg);
				background-image: linear-gradient(to right, transparent 0%, rgba(#fff,0.4) 50%, transparent 100%);
			}

			&:hover{
				background: darken(#55C194, 10%);

				&:before{
					left:100%;
					transition: left 0.6s linear;
				}
			}
		}

		input{
			background: #dad9d9;
			border:none;
			border-bottom:3px solid rgba(#000,0.1);
			border-radius: 5px;
			padding: 0.375rem 0.75rem;
			font-size: 16px;
			width: 100%;

			&:focus{
				border-bottom:3px solid #1CC196;
				outline: none;
			}
		}
	}

	.listagem-agenda{
		a:hover{
			text-decoration: none;
		}
	}

	.card-agenda{
		border: none;
		background: #e9e9e9;
		border-radius: 5px;
		overflow:hidden;
		width: 270px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		.card-foto{
			margin-bottom: 0;
			position: relative;
			overflow: hidden;

			figcaption{
				position: absolute;
				top: 10px;
				left: 10px;
				z-index: 2;
				background: #495052;
				color: #FFF;
				padding: 5px 10px;
				border-radius: 5px;
				font-size: 12px;
				font-weight: 600;
			}
		}

		.card-header{
			background: #495052;
			color: #FFF;
		}

		.card-body{
			color: #777;
			border-bottom: 5px solid rgba(#000,0.2);
			transition:all 0.3s linear;
			border-radius: 0 0 5px 5px;
		}

		.svg-icon{
			width:1em;
			height: 1em;
			display: inline-block;
			vertical-align: baseline;

			use{
				fill: currentcolor;
			}
		}

		.nome,
		.data{
			padding-left: 1.5em;
			position: relative;

			.far,
			.svg-icon{
				position:absolute;
				left:0;
				top:0.15em;
			}
		}

		.description{
			margin-bottom: 10px;

			&:before{
				content:'';
				width: 3em;
				height:5px;
				background: currentcolor;
				display: block;
				margin: 10px 0;
			}
		}

		.btn{
			background: #329D72;
			color: #FFF;
			border: none;
			border-bottom:3px solid rgba(#000,0.1);
		}

		&:hover{
			.card-body{
				background: #55C194;
				color:#FFF;
			}
		}
	}

	.paginacao{
		margin-top: 80px;

		& > *{
			background: #329D72;
			border-radius: 5px;
			border: none;
			border-bottom: 5px solid rgba(#000,0.2);
			color: #FFF;
			padding: 0.375rem 0.75rem;
		}

		.active{
			background: #495153;
			cursor:default;
		}
	}

	.agenda-detalhes{
		padding:15px;
		position: relative;
		min-height: 90px;
		background: #efeeee;
		$bg-titulo: #6FC191;

		.data-flutuante{
			padding: 15px 30px;
			color: #FFF;
			background: $bg-titulo;
			top:-15px;
			left:30px;
			max-width: calc(100% - 60px);
			position: absolute;
			z-index:5;
			font-size: 20px;
			text-shadow: -1px 1px darken($bg-titulo, 20%);

			&:before{
				content:'';
				position: absolute;
				border-top:15px solid transparent;
				border-left:15px solid transparent;
				border-right:15px solid darken($bg-titulo, 20%);
				right: 100%;
				top:0;
				filter: drop-shadow(-1px 1px darken($bg-titulo, 20%));
			}

			.icon{
				margin-right: 10px;
				margin-top: 8px;
			}

			.dia{
				font-size: 30px;
				font-weight:900;
			}
		}

		.header{
			border-bottom:1px solid rgba(#000,0.2);
			margin-bottom: 20px;

			&:after{
				content:'';
				display: block;
				width: percentage(470/690);
				background: rgba(#000,0.2);
				height:3px;
				margin-top:15px;
			}

			.label{
				padding:5px 10px;
				display: inline-block;
				background: #419639;
				color: #FFF;
				margin-bottom:10px;
			}

			h2{
				font-weight: 900;
			}

			.autor{
				strong{
					color: #419639;
				}
			}

			.autor,
			.data-post{
				color: #777;
			}
		}

		.footer{
			padding-top: 15px;
			border-top:1px solid rgba(#000,0.2);
		}

		.rede-social{
			display: inline-block;
			width: 2em;
			height:2em;
			line-height:2em;
			text-align: center;
			border-radius:100%;
			margin: 0 10px;
			transition: all 0.3s linear;

			&.fa-facebook-f,
			&.fa-facebook{
				background: materialColor('blue', 'darken-2');
				color:#FFF;

				&:hover{
					background: materialColor('blue', 'darken-4');
				}
			}

			&.fa-twitter{
				background: materialColor('blue', 'lighten-1');
				color:#FFF;

				&:hover{
					background: materialColor('blue', 'darken-2');
				}
			}

			&.fa-instagram{
				background: materialColor('purple', 'darken-1');
				color:#FFF;

				&:hover{
					background: materialColor('purple', 'darken-3');
				}
			}

			&.fa-pinterest,
			&.fa-pinterest-p{
				background: materialColor('red', 'darken-1');
				color:#FFF;

				&:hover{
					background: materialColor('red', 'darken-3');
				}
			}

			&.fa-linkedin,
			&.fa-linkedin-in{
				background: materialColor('blue', 'darken-2');
				color:#FFF;

				&:hover{
					background: materialColor('blue', 'darken-4');
				}
			}

			&:hover{
				text-decoration: none;
				box-shadow:0 0 4px rgba(#000,0.8);
			}
		}

		@include media-breakpoint-up (lg) {
			.redes-sociais{
				display: flex;
				align-items: center;

				p{
					margin-bottom: 0;
					margin-right: 10px;
				}
			}
		}
	}

	.veja-tambem{
		border:1px solid rgba(#000,.2);
		background: #FFF;
		margin-bottom: 40px;

		.header{
			border-left: 5px solid #1C9647;
			padding:0 15px;
			margin-top:15px;

			h2{
				margin-bottom:0;
				font-size: 25px;
			}

			p{
				color: #777;
			}
		}

		.body{
			padding: 15px;
		}

		.media{
			padding: 15px 0;
			border-top:1px solid rgba(#000,0.2);

			h3{
				font-size: 16px;
				font-weight: 900;
				line-height:1.3; 
				max-height: (16px * 1.3 * 3);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			.foto{
				margin-right:10px;
				img{
					border-radius: 8px;
				}
			}

			p{
				color: #777;
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}

	.btn-voltar{
		background: #EFEEEE;
		color: #6fc191;
		border-radius:0;
		transition:all 0.3s linear;

		&:hover{
			background: #6fc191;
			color: #fff
		}
	}

	.calendario{
		margin-left:auto;
		margin-right:auto;
	}

	.fotos{
		h2{
			margin-bottom: 30px;
			font-size: 20px;
			font-weight: 700;

			&:after{
				content:'';
				display: block;
				width: toEm(120, 20);
				max-width: 100%;
				height: 5px;
				border-radius: 5px;
				background: #6FC191;
				margin-top:10px;
			}
		}

		.owl-carousel{
			margin-left:auto;
			margin-right:auto;
			width: calc(100% - 60px);

			.owl-nav{
				& > * {
					width: 30px;
					height: 100%;
					background: #6FC191;
					color:#FFF;
					position:absolute;
					top:0;
					overflow: hidden;
					text-indent:-999px;
					cursor:pointer;
					@extend .fas;

					&:not(.disabled):hover{
						background: darken(#6FC191, 10%);
					}

					&:before{
						position: absolute;
						text-indent: 0;
						left:50%;
						top:50%;
						transform: translate(-50%, -50%)
					}

					&.disabled{
						cursor: default;
						background:#ddd;
						color:#444;
					}
				}

				.owl-prev{
					left:-30px;
					@extend .fa-chevron-left;
				}

				.owl-next{
					right: -30px;
					@extend .fa-chevron-right;
				}
			}
		}
	}
}