@import "_modelo-1.scss";
@import "_modelo-2.scss";
@import "_modelo-3.scss";

.galeria-fotos{
	.album{
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		position: fixed;
		z-index: 1024;
		background: rgba(#000,0.8);
		display: none;

		&.show{
			display: block;
			animation: fadeIn 0.6s linear;
		}

		.content{
			width: calc(100vh - 200px);
			max-width: calc(100vw - 100px);
			position: relative;
			top:50%;
			transform: translateY(-50%);
			margin-left: auto;
			margin-right: auto;
			z-index: 5;
		}

		.fechar{
			color:#FFF;
		}

		.header{
			text-align: right;
			min-height: 40px;
		}

		.carousel{
			max-width: calc(100vh - 200px);
		}

		.wrapper-carousel{
			position: relative;
		}

		.prev,
		.next{
			width:30px;
			height: 30px;
			display: inline-flex;
			align-items:center;
			justify-content:center;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			background: #FFF;
			border-radius: 100%;

			&:hover{
				text-decoration: none;
			}
		}

		.prev{
			right: calc(100% + 10px);
		}

		.next{
			left: calc(100% + 10px);
		}

		.carousel-indicators{
			position: static;
			bottom: 0;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			flex-wrap: nowrap;
			overflow: auto;
			min-height: 130px;
			justify-content: flex-start;

			li{
				width: 100px;
				min-width: 100px;
				height: auto;
				text-indent: 0;
				background: none;
			}
		}
	}
}