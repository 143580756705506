#agenda-3{
	padding-bottom: 80px;

	.page-header{
		padding: 80px 0;

		h1{
			overflow: hidden;

			span{
				display: inline-block;
				position: relative;

				&:before{
					content: '';
					display: block;
					border-top: 5px solid #FFEB68;
					width: 100px;
					top:50%;
					left:calc(100% + 30px);
					transform: translate(0, -50%);
					position: absolute;
				}
			}
		}
	}

	.media-evento{
		padding-bottom: 15px;
		border-bottom: 1px dashed rgba(#000,0.2);

		h2{
			font-size: 18px;
			font-weight: 900;
		}

		.inicio{
			font-size: 12px;
		}
	}

	.data-area{
		margin-right: 15px;
	}

	.data{
		width: 115px;
		height: 115px;
		background: #FFEB68;
		display: flex;
		flex-direction:column;
		justify-content:center;
		text-align: center;
		font-size: 20px;
		font-weight: 300;
		margin-bottom:10px;

		.dia{
			font-weight: 900;
			font-size:30px;
		}
	}

	.desc{
		color: #777;
	}

	.btn-loadmore{
		@include button-variant(#242424, #242424);
		color: #FFF;
	}
}