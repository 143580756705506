#videos-1{

	.titles{
		color: #006094;
	}

	.video-thumb{
		width: 270px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		.foto{
			margin-bottom: 10px;
			position: relative;
			overflow: hidden;

			&:before{
				content:'';
				display: block;
				border-top:10px solid transparent;
				border-bottom:10px solid transparent;
				border-left:20px solid #F70017;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
			}

			&:after{
				content:'';
				position: absolute;
				width: 50%;
				height: 200%;
				left: -100%;
				// left: 50%;
				top:50%;
				transform: translateY(-50%) rotate(15deg);
				background-image: linear-gradient(to right, transparent 0%, rgba(#fff,0.4) 50%, transparent 100%);
			}
		}

		.nome{
			margin-bottom: 10px;
			color: #006094;
			text-align: center;
		}

		.details{
			padding:5px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			border-top: 1px solid rgba(0,0,0,0.2);
			border-bottom: 1px solid rgba(0,0,0,0.2);
			color:#777;

			span{
				color: #006094;
			}
		}

		a:hover{
			text-decoration: none;

			.foto:after{
				left: 100%;
				transition: left 0.6s linear;
			}
		}
	}

	

	.paginacao{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 60px;

		& > *{
			margin: 0;
			border-width: 1px;
			border-color: #CCC;
			padding:0.35rem 0.75rem;
			color: #444;

			&:first-child{
				border-radius: 4px 0 0 4px;
			}

			&:last-child{
				border-radius: 0 4px 4px 0;
			}

			& + * {
				margin-left: -1px;
			}

		}

		span{
			cursor: default;
		}
		
		.active,
		.active:hover {
			background: $primary;
			color: #FFF;
		}
	}
}