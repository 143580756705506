.links-uteis#modelo-1{
	h2{
		font-size: 25px;
		text-align: center;
		margin-bottom: 60px;
		color: #333;
	}

	.link-util{
		width: 200px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
		text-align: center;

		a:hover{
			text-decoration: none;
			// color: #ECB338;

			.foto{
				transform: scale(calc(210/200));
				box-shadow:0 0 8px rgba(#000,0.8);
			}
		}

		.foto{
			margin-bottom: 10px;
			overflow: hidden;
			border-radius:100%;
			padding:15%;
			background:#FFF;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow:0 0 8px rgba(#000,0.4);
			transition:all 0.3s linear;
		}

		.nome{
			font-weight: bold;
			word-break: break-all;
		}
	}

	.btn{
		background: #ECB338;
		color: #6B4C09;
		font-weight: 900;
	}
}