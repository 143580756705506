@import "_modelo-1.scss";
@import "_modelo-2.scss";
@import "_modelo-3.scss";

.clientes-e-parceiros{
	.row.clientes{
		@include media-breakpoint-between(sm,md){
			& > *{
				&:before{
					content:'';
					position:absolute;
					height:90%;
				}
			}
		}
	}

	.cliente{
		margin-bottom:30px;
		.nome{
			text-align: center;
			margin-top:15px;
		}
	}
}