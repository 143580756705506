.internas__conteudo{
	background-image: url('../images/bg__gerak.png');
	background-size: 1920px 2654px;
	background-position: center bottom;
	background-repeat: no-repeat;
	padding: 64px 0px 54px 0px;

	&.alquiler{
		padding: 64px 0px 0px 0px;
	}
}
.breadcrump__custom{
	font-family: 'montserratregular';
	font-size: 13px;
	margin-bottom: 40px;

	span{
		font-family: 'montserratblack';
	}
}
.agencia__int__titulo{
	font-family: 'montserratblack';
	font-size: 16px;
}
.agencia__int__sub{
	font-family: 'montserratmedium';
	font-size: 16px;
	margin-bottom: 30px;
}
.agencia__int__desc{
	font-family: 'montserratregular';
	font-size: 13px;
}
.agencia__header{
	margin-bottom: 50px;
}
.card__equipe{
	display: flex;
	margin-bottom: 30px;
	align-items: center;
}
.equipe__img{
	margin-right: 25px;
}
.nome__equipe{
	font-family: 'montserratblack';
	font-size: 16px;
	margin-bottom: 50px;
}
.equipe__desc{
	font-family: 'montserratregular';
	font-size: 13px;
}
@include media-breakpoint-down (md) {
	.card__equipe{
		flex-direction: column;
		text-align: center;
	}
	.equipe__img{
		margin-right: 0px;
		margin-bottom: 15px;
	}
	.nome__equipe{
		margin-bottom: 20px;
	}
}
