.depoimentos#depoimento-mod-2{

	.depoimento{
		@include clearfix;

		.avatar{
			float: left;
			width: 80px;
			height: 80px;
			border-radius: 100%;
			margin-right: 10px;
			margin-bottom: 10px;
			overflow: hidden;
			box-shadow: 0 0 8px #000;
			border:5px solid #fff;
		}

		color: #989898;
		font-size: 14px;

		a{
			color: #9D192B;
		}

		.title{
			font-size: 16px;
			color: #000;
			margin-bottom:15px;

			.empresa{
				font-size:12px;
			}
		}

	}
}