#perguntas-3{

	.perguntas{
		padding-left: 0;
		border:1px solid rgba(#000,0.2);
		list-style: none;

		a,
		span{
			padding:10px 15px;
			display: block;
			
			&:hover{
				text-decoration: none;
				color: #FFF;
				background: #43C379;
			}
		}

		li + li{
			border-top:1px solid rgba(#000,0.2);
		}

		.active a,
		.active span {
			color: #FFF;
			background: #43C379;

		}
	}

	.lazyimage{
		margin-right:auto;
		margin-left:auto;
	}
}