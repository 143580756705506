#modelo-1{
	padding-bottom: 80px;

	.page-header{
		background: #f0f0f0;
		padding-top:40px;
		padding-bottom:40px;
		text-align: center;
		margin-bottom: 60px;

		.breadcrumb{
			padding:0 30px 15px 30px;
			width: 250px;
			max-width: 100%;
			background: none;
			display: inline-flex;
			justify-content:center;
			margin-bottom: 0;
			border-radius:0;
			border-bottom: 2px solid rgba(#000,0.2);
			position: relative;

			span{
				color: #888;
				cursor: default;
			}

			&:before{
				content:'';
				position: absolute;
				left:50%;
				bottom:-2px;
				transform: translateX(-50%);
				height: 5px;
				width: 60px;
				background: #EC7D00;
			}

			li + li:before{
				content:'/';
				display: inline-block;
				margin:0 10px;
			}
		}
	}

	.noticia{
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid rgba(#000,0.2);

		&:hover{

			a{
				color: inherit;
				text-decoration: none
			}

			.title-noticia{
				color: #EC7D00;
				text-decoration: underline;
			}

			.foto:before{
				opacity: 1;
			}

			.foto:after{
				top: 50%;
			}
		}

		.foto{
			position: relative;
			overflow: hidden;

			&:before {
				content:'';
				display: block;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 2;
				background: rgba(#FFF,0.5);
				position: absolute;
				transition:all 0.3s linear;
				opacity: 0;
			}

			&:after{
				content: fa-content($fa-var-search);
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				padding: 0.3rem 0.75rem;
				display: inline-block;
				position: absolute;
				border-radius: 0.4rem;
				z-index: 3;
				left: 50%;
				top: -50%;
				transform: translate(-50%,-50%);
				background: rgba(#222, 0.8);
				color:#FFF;
				transition:top 0.3s linear;
			}
		}

		.publicado{
			text-align: right;
			font-size: 12px;
			color:#999;
			margin-bottom: 7px;
		}
		.title-noticia{
			font-size: 18px;
			line-height: 1.4;
			max-height: (1em * 1.4 * 4);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			margin-bottom: 7px;
			margin-top: 0;
		}

		.desc{
			font-size: 16px;
			line-height: 1.4;
			max-height: (1em * 1.4 * 4);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			margin-bottom: 7px;
		}

		.redes-sociais a{
			display: inline-block;
			margin-right: 10px;
			color: #999;
			padding:0.3em;

			&:hover{
				color: #EC7D00;
			}
		}

		@include media-breakpoint-down(md) {
			width: 350px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-up(lg) {
			display: flex;

			.foto{
				min-width: 250px;
				margin-right:15px;
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-up(xl) {

			.foto{
				min-width: 350px;
				margin-right:15px;
				margin-bottom: 0;
			}
		}
	}

	.mais-lidas{
		margin-bottom: 30px;

		.card-header{
			background: #EC7D00;
			color: #FFF;
			text-align: center;
		}

		.media{
			padding: 15px 15px 0 15px;

			h3{
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.4;
				max-height: (1em * 1.4 * 4);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
			}

			.media-body{
				border-bottom: 1px solid rgba(#000,0.2);
				padding-bottom: 15px;
			}

			a:hover{
				color: #EC7D00;
			}
		}

		.rank{
			min-width: 55px;
			font-size: 30px;
			padding-right:10px;
		}
	}

	.noticia-detalhes{
		.header{
			margin-bottom: 15px;
		}

		.publicado{
			font-size: 14px;
			color: #999;
			margin-bottom: 10px;
		}

		.titulo{
			font-weight: 900;
			margin-bottom: 15px;
		}

		.subtitulo{
			font-size: 16px;
			font-weight: 400;
			color: #999;
			margin-bottom: 10px;
		}

		.autor{
			margin-bottom: 10px;
		}

		.compartilhe{
			text-align: right;

			a{
				display: inline-block;
				padding:0.4rem 0.8rem;
				border-radius: 0.4rem;
				margin:0 5px;
				transition:all 0.3s linear;

				&:hover{
					text-decoration: none;
				}

				&.fa-facebook,
				&.fa-facebook-f{
					background:materialColor('blue', 'darken-2');
					color:#FFF;

					&:hover{
						background: materialColor('blue', 'darken-4')
					}
				}

				&.fa-instagram{
					color:#FFF;
					background: materialColor('purple','darken-2');
					background: linear-gradient(45deg, materialColor('purple', 'darken-3'),materialColor('orange', 'orange'));

					&:hover{
						background: materialColor('purple','darken-4');
					background: linear-gradient(45deg, materialColor('purple', 'darken-4'),materialColor('orange', 'darken-2'));
					}
				}

				&.fa-twitter{
					color: #FFF;
					background: materialColor('blue', 'blue');

					&:hover{
						background: materialColor('blue', 'darken-2');
					}
				}

				&.fa-pinterest,
				&.fa-pinterest-p{
					color: #FFF;
					background: materialColor('red', 'darken-2');

					&:hover{
						background: materialColor('red', 'darken-4');
					}
				}
			}
		}

		.content{
			@include clearfix;

			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
}