#modelo-2{
	padding-bottom: 80px;

	.masonry-row{
		margin-bottom: 40px;
	}

	.btn-loadmore{
		padding:10px 30px;
		border-radius: 0;
		@extend .btn-secondary;
	}

	.noticia{
		width: 350px;
		max-width: 100%;
		transition: all 0.3s linear;

		&:hover{
			filter: drop-shadow(0 0 8px rgba(#000,.4));
		}

		.thumbnail{
			min-height: 70px;
			position: relative;

			&:before{
				content:'';
				position: absolute;
				bottom: -1px;
				z-index: 5;
				border-bottom:34px solid #FFF;
				border-right:34px solid transparent;
				border-left:34px solid transparent;
				left: calc(50% - 34px);
			}

			&:after{
				content: fa-content($fa-var-chevron-up);
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				position: absolute;
				left: 50%;
				bottom: 0px;
				z-index: 6;
				transform:translateX(-50%);
				font-size:20px;
				color: rgba(#000,0.3);
				line-height: 1;
			}
		}
		
		.noticia-content{
			border:1px solid rgba(0,0,0,0.2);
			background: #FFF;
			position: relative;
			
			&:before{
				content:'';
				position: absolute;
				border-left: 36px solid transparent;
				border-right: 36px solid transparent;
				border-bottom: 36px solid #CCC;
				bottom:100%;
				left:calc(50% - 36px);
			}
		}

		.noticia-body{
			padding:15px;
		}

		.data{
			width: 102px;
			text-align: center;
			background: #9F0000;
			color: #FFF;
			margin-top: -60px;
			position: relative;
			z-index: 7;
			margin-left: -1px;

			.thumbnail{
				min-height: 60px;
			}

			.dia{
				font-weight: 900;
				font-size: 25px;
				line-height: 25px;
			}

			.dia-mes{
				padding: 5px;
				height: 60px;
			}

			.mes{
				font-size: 16px;
				line: 16px;
			}

			.ano{
				padding: 5px 0;
				background: #D30000;
			}
		}

		.autor-pub{
			font-size: 12px;
			margin-left: -15px;
			margin-right: -15px;
			display: flex;
			margin-bottom: 5px;
		}
		.divider{
			border-top:1px solid rgba(#000,0.2);
			margin-bottom:10px;

			&:before{
				content:'';
				display: block;
				width: 70px;
				height: 3px;
				background: #D30000;
				transform:translateY(-50%)
			}
		}

		.autor,
		.publ{
			flex-grow:1;
			padding: 0 15px;
		}

		.titulo{
			font-size: 18px;
			font-weight: 900;
			line-height: 1.4;
			max-height: (1em * 1.4 * 2);
			margin-bottom:10px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}

		.content{
			font-size: 16px;
			line-height: 1.4;
			max-height: (1em * 4 * 1.4);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			margin-bottom: 15px;
		}

		.btn{
			padding-right:1em;
			padding-left:1em;
			border-width: 2px;
			border-radius: 0;
			min-width: 150px;

			@extend .btn-outline-secondary;
		}
	}

	.veja-tambem{
		padding-top: 15px;
		border-radius:0;
		margin-bottom: 30px;
		background: #FFF;

		.card-header{
			border-radius: 0;
			background:#FFF;
			padding-top:0;
			padding-bottom:0;
			margin-bottom: 15px;
			border-bottom:none;
			border-left:3px solid #D21111;

			h2,
			p{
				margin:0;
			}

			h2{
				font-size: 18p;
			}

			p{
				font-size: 14px;
				color:#777;
			}
		}

		.btn-todas-noticias{

			border-radius: 0;
			margin-top: 10px;
			position: relative;
			overflow: hidden;
			white-space: normal;

			&:before{
				content:'';
				width: 33.33%;
				height: 200%;
				background: linear-gradient(to right, transparent, currentcolor, transparent);
				position: absolute;
				left:-100%;
				top:50%;
				transform: translate(0 , -50%) rotate(15deg);
				opacity: 0.5;
			}

			@extend .btn-danger;

			&:hover{
				&:before{
					left: 100%;
					transition: left 0.6s linear;
				}
			}
		}

		.noticia-thumb{
			padding: 15px 0;

			& + .noticia-thumb{
				border-top: 1px solid rgba(#000,0.2);
			}
		}

		.media{

			h3{
				font-size: 16px;
				line-height: 1.4;
				max-height: (1em * 1.4 * 3);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			.lazyimage{
				border-radius:4px;
				margin-right: 10px;
			}

			.publicado{
				font-size: 14px;
				color:#777;
			}
		}

		a:hover{
			text-decoration: none;
			color:#D21111;
		}
	}

	.title{
		border-left: 3px solid #D21111;
		padding-left: 15px;
		overflow: hidden;
		margin-bottom: 20px;

		span{
			display: inline-block;
			position: relative;

			&:before{
				content:'';
				width: 100vw;
				border-top: 1px solid rgba(#000,0.2);
				position: absolute;
				left: calc(100% + 30px);
				top: 50%;
			}
		}
	}

	.noticia-detalhe{
		.header{
			border-bottom: 1px solid #CCC;
			padding-bottom: 15px;
			margin-bottom: 20px;

			.postado{
				font-size: 14px;
				color:#777;
			}

			.titulo{
				font-weight: 900;
			}

			.subtitulo{
				font-weight: 400;
				font-size: 16px;
				color:#777;
			}
		}

		.content{
			img{
				max-width: 100%;
				height: auto;
			}

			@include clearfix;
		}

		.footer{
			margin-top: 20px;
			padding:10px 0px;
			border-top:1px dashed rgba(#000,0.3);
			border-bottom:10px solid rgba(#000,0.2);
		}

		.redes-sociais{
			a{
				display: inline-block;
				padding:0.6rem 0.9rem;
				border-radius:4px;
				color:#FFF;
				transition:all 0.3s linear;

				& + a{
					margin-left: 10px;
				}

				&:hover{
					text-decoration: none;
				}

				&.fa-facebook{
					background: materialColor('blue','darken-2');

					&:hover{
						background: materialColor('blue','darken-4');
					}
				}

				&.fa-twitter{
					background: materialColor('blue','blue');

					&:hover{
						background: materialColor('blue','darken-2');
					}
				}

				&.fa-instagram{
					background: linear-gradient(45deg, materialColor('purple', 'darken-3'),materialColor('orange', 'orange'));
					text-shadow: 0 0 1px rgba(#000,0.2);
				}

				&.fa-pinterest-p{
					background: materialColor('red','darken-2');

					&:hover{
						background: materialColor('red','darken-4');
					}
				}
			}
		}
	}
}