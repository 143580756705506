/*!
Projeto desenvolvido pela GV8 Sites & Sitemas ®

Visite nosso site
https://www.gv8.com.br

Nossas redes sociais:===========================================
https://www.facebook.com/gv8website/
https://www.instagram.com/gv8sitesbr/
https://twitter.com/GV8
https://www.youtube.com/channel/UCAuo8NzH6uHwereyeJ2UjNA/videos
*/

@import "functions/functions";

@import "_variaveis";

@import "mixins/mixins";

@import "vendors/vendors";

@import "components/components";

@import "tipografia/tipografia";

@import "site/site";

@import "paginas-internas/_paginas_internas.scss";
